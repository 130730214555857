import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  render() {
    return (
      <Layout>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <h1>Contact</h1>
                  <p>Maybe you liked something you read here. Maybe you hated it. Either way, if you feel compelled to tell me your thoughts then hit me up on my email or on social media.</p>
                  <p>rbtprograms AT gmail DOT com</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
